export const Q1_LABOR_DATA = {
  id: 17,
  question:
    'How does my driver mileage rate compare to the current national average?',
  inputs: {
    // 'What type of fleet do you have?': {
    //   title:
    //     'Great question. To give you a good answer, tell me what type of fleet you have.',
    //   type: 'radio',
    //   options: [
    //     { label: 'Truckload - national', value: 'Truckload - national' },
    //     { label: 'Truckload - regional', value: 'Truckload - regional' },
    //     { label: 'LTL - national', value: 'LTL - national' },
    //     { label: 'LTL - regional', value: 'LTL - regional' },
    //     { label: 'Private', value: 'Private' },
    //     { label: 'Specialty/Other', value: 'Specialty/Other' },
    //   ],
    // },
    // 'What is your title?': {
    //   title: 'Thanks. And what is your title?',
    //   type: 'input',
    // },
    'How many drivers do you have currently?': {
      title: 'How many drivers are currently in your fleet?',
      type: 'input',
      validate: 'number',
    },
    'What is your average annual mileage per truck?': {
      title:
        'Can you also tell me what your average annual mileage is per truck?',
      type: 'input',
      validate: 'number',
    },
    'What is your current driver turnover rate?': {
      title:
        'Got it. And what is your current driver turnover rate? (Enter percentage value) ',
      type: 'input',
      validate: 'number',
    },
    'What are you currently paying drivers per mile?': {
      title:
        'Finally, what are you currently paying drivers per mile? (Enter cents/mile value)',
      type: 'input',
      validate: 'number',
    },
  },
};

export const Q2_LABOR_DATA = {
  id: 18,
  question:
    'How does my driver bonus program compare to the current national average?',
  inputs: {
    // 'What type of fleet do you have?': {
    //   title:
    //     'Great question. To give you a good answer, tell me what type of fleet you have.',
    //   type: 'radio',
    //   options: [
    //     { label: 'Truckload - national', value: 'Truckload - national' },
    //     { label: 'Truckload - regional', value: 'Truckload - regional' },
    //     { label: 'LTL - national', value: 'LTL - national' },
    //     { label: 'LTL - regional', value: 'LTL - regional' },
    //     { label: 'Private', value: 'Private' },
    //     { label: 'Specialty/Other', value: 'Specialty/Other' },
    //   ],
    // },
    // 'What is your title?': {
    //   title: 'Thanks. And what is your title?',
    //   type: 'input',
    // },
    'How many drivers do you have currently?': {
      title: ' Now how many drivers do you have currently?',
      type: 'input',
      validate: 'number',
    },
    'What is your average annual mileage per truck?': {
      title: 'Thanks. Now, what is your average annual mileage per truck?',
      type: 'input',
      validate: 'number',
    },
    'What is your current driver turnover rate?': {
      title:
        'Also, what is your current driver turnover rate? (Enter percentage value) ',
      type: 'input',
      validate: 'number',
    },
    'How much has the cost of insurance increased': {
      title:
        'Finally, tell me about your current bonus amounts so we can make a good comparison.',
      type: 'multiple-inputs',
      validate: 'number',
      inputs: [
        {
          name: 'What is your Sign-on bonus amount?',
          label: 'Sign-on (Enter amount)',
          validate: 'number',
        },
        {
          name: 'What is your Safety bonus amount?',
          label: 'Safety (Enter amount)',
          validate: 'number',
        },
        {
          name: 'What is your Retention bonus amount?',
          label: 'Retention (Enter amount)',
          validate: 'number',
        },
        {
          name: 'What is your Referral bonus amount?',
          label: 'Referral (Enter amount)',
          validate: 'number',
        },
        {
          name: 'What is your Fuel bonus amount?',
          label: 'Fuel (Enter amount)',
          validate: 'number',
        },
        {
          name: 'What are your other bonus amounts?',
          validate: 'number',
          label: 'Other (Enter text and/or dollar values)',
        },
      ],
    },
  },
};

export const Q3_LABOR_DATA = {
  id: 19,
  question:
    'How does my driver turnover rate compare to the current national average?',
  inputs: {
    // 'What type of fleet do you have?': {
    //   title:
    //     'That’s an important question. Before we tell you, what type of fleet do you have?',
    //   type: 'radio',
    //   options: [
    //     { label: 'Truckload - national', value: 'Truckload - national' },
    //     { label: 'Truckload - regional', value: 'Truckload - regional' },
    //     { label: 'LTL - national', value: 'LTL - national' },
    //     { label: 'LTL - regional', value: 'LTL - regional' },
    //     { label: 'Private', value: 'Private' },
    //     { label: 'Specialty/Other', value: 'Specialty/Other' },
    //   ],
    // },
    // 'What is your title?': {
    //   title: 'Got it. Next, what is your title?',
    //   type: 'input',
    // },
    'How many drivers do you have currently?': {
      title: 'How many drivers do you have currently?',
      type: 'input',
      validate: 'number',
    },
    'What is your average annual mileage per truck?': {
      title: 'Perfect. Now, what is your average annual mileage per truck?',
      type: 'input',
      validate: 'number',
    },
    'What is your current driver turnover rate?': {
      title:
        'Finally, what is your current driver turnover rate? (Enter percentage value)',
      type: 'input',
      validate: 'number',
    },
  },
};

export const Q4_LABOR_DATA = {
  id: 20,
  question:
    'How do my detention pay and parking compensation policies compare to other fleets?',
  inputs: {
    // 'What type of fleet do you have?': {
    //   title:
    //     'Great question. Before we get into it, what type of fleet do you have?',
    //   type: 'radio',
    //   options: [
    //     { label: 'Truckload - national', value: 'Truckload - national' },
    //     { label: 'Truckload - regional', value: 'Truckload - regional' },
    //     { label: 'LTL - national', value: 'LTL - national' },
    //     { label: 'LTL - regional', value: 'LTL - regional' },
    //     { label: 'Private', value: 'Private' },
    //     { label: 'Specialty/Other', value: 'Specialty/Other' },
    //   ],
    // },
    // 'What is your title?': {
    //   title: 'Got it. Next, what is your title?',
    //   type: 'input',
    // },
    'How many drivers do you have currently?': {
      title: 'How many drivers are currently in your fleet?',
      type: 'input',
      validate: 'number',
    },
    'What is your average annual mileage per truck?': {
      title: 'Thanks. And what is your annual average mileage by truck',
      type: 'input',
      validate: 'number',
    },
    'What is your current driver turnover rate?': {
      title:
        'Perfect. Now, what is your current driver turnover rate (Enter percentage value)',
      type: 'input',
      validate: 'number',
    },
    'Do you offer detention pay now?': {
      title: 'Got it. Do you happen to be self-insured?',
      type: 'radio',
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
    },
    'Do you offer parking compensation?': {
      title: 'Thanks. Do you offer parking compensation?',
      type: 'radio',
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
    },
    'How much do you pay per hour for detention?': {
      title:
        'A few more. How much do you pay per hour for detention? (Enter dollar value)',
      type: 'input',
      validate: 'number',
    },
    'How soon after arrival does the detention pay commence?': {
      title:
        'And finally, how soon after arrival does the detention pay commence? (Enter numeric value - hours)',
      type: 'input',
      validate: 'number',
    },
  },
};

export const Q5_LABOR_DATA = {
  id: 21,
  question:
    'How does my driver replacement cost compare to the current national average?',
  inputs: {
    // 'What type of fleet do you have?': {
    //   title: 'A very good question. First, tell us about your fleet?',
    //   type: 'radio',
    //   options: [
    //     { label: 'Truckload - national', value: 'Truckload - national' },
    //     { label: 'Truckload - regional', value: 'Truckload - regional' },
    //     { label: 'LTL - national', value: 'LTL - national' },
    //     { label: 'LTL - regional', value: 'LTL - regional' },
    //     { label: 'Private', value: 'Private' },
    //     { label: 'Specialty/Other', value: 'Specialty/Other' },
    //   ],
    // },
    // 'What is your title?': {
    //   title: 'Got it. Next, what is your title?',
    //   type: 'input',
    // },
    'How many drivers do you have currently?': {
      title: 'How many drivers are currently in your fleet?',
      type: 'input',
      validate: 'number',
    },
    'What is your current driver turnover rate?': {
      title:
        'Thanks. Now, what is your current driver turnover rate? (Enter percentage value)',
      type: 'input',
      validate: 'number',
    },
    'What are your total annual driver recruitment and retention costs?': {
      title:
        'Finally, what is your average total cost to replace one driver (include recruitment advertising, onboarding costs, training costs, sign-on bonuses, and recruitment staffing costs)?',
      type: 'input',
      validate: 'number',
    },
  },
};

export const Q6_LABOR_DATA = {
  id: 22,
  question: 'How does my driver benefits package compare to other fleets?',
  inputs: {
    // 'What type of fleet do you have?': {
    //   title:
    //     'Great question. To give you a good answer, tell me what type of fleet you have.',
    //   type: 'radio',
    //   options: [
    //     { label: 'Truckload - national', value: 'Truckload - national' },
    //     { label: 'Truckload - regional', value: 'Truckload - regional' },
    //     { label: 'LTL - national', value: 'LTL - national' },
    //     { label: 'LTL - regional', value: 'LTL - regional' },
    //     { label: 'Private', value: 'Private' },
    //     { label: 'Specialty/Other', value: 'Specialty/Other' },
    //   ],
    // },
    // 'What is your title?': {
    //   title: 'Got it. Next, what is your title?',
    //   type: 'input',
    // },
    'How many drivers do you have currently?': {
      title: 'How many drivers are currently in your fleet?',
      type: 'input',
      validate: 'number',
    },
    'What is your current driver turnover rate?': {
      title:
        'Got it. Now, what is your current driver turnover rate (Enter percentage value)',
      type: 'input',
      validate: 'number',
    },
    'Which employee benefits you currently offer to drivers?': {
      title: 'Finally, please select all of the benefits you currently offer',
      isAllowInput: true,
      type: 'checkbox',
      options: [
        { label: 'Health insurance', value: 'Health insurance' },
        { label: 'Dental insurance', value: 'Dental insurance' },
        { label: 'Vision insurance', value: 'Vision insurance' },
        { label: 'Paid vacation', value: 'Paid vacation' },
        { label: 'Paid sick leave', value: 'Paid sick leave' },
        { label: 'Per diem', value: 'Per diem' },
        { label: '401K', value: '401K' },
        { label: 'Life insurance', value: 'Life insurance' },
        {
          label: 'Employee ownership/profit sharing',
          value: 'Employee ownership/profit sharing',
        },
        {
          label: 'Other',
          type: 'input',
        },
      ],
    },
  },
};

export const Q7_LABOR_DATA = {
  id: 23,
  question:
    'How does my technician compensation compare to the national average?',
  inputs: {
    // 'What type of fleet do you have?': {
    //   title:
    //     'Great question. Before we answer, tell us about the type of fleet you have.',
    //   type: 'radio',
    //   options: [
    //     { label: 'Truckload - national', value: 'Truckload - national' },
    //     { label: 'Truckload - regional', value: 'Truckload - regional' },
    //     { label: 'LTL - national', value: 'LTL - national' },
    //     { label: 'LTL - regional', value: 'LTL - regional' },
    //     { label: 'Private', value: 'Private' },
    //     { label: 'Specialty/Other', value: 'Specialty/Other' },
    //   ],
    // },
    // 'What is your title?': {
    //   title: ' Got it. Next, what is your title?',
    //   type: 'input',
    // },
    'What the most of your maintenance shops are located.?': {
      title: 'Where are most of your maintenance shops located?',
      type: 'radio',
      options: [
        { label: 'West', value: 'West' },
        { label: 'MidWest', value: 'MidWest' },
        { label: 'SouthWest', value: 'SouthWest' },
        { label: 'SouthEast', value: 'SouthEast' },
        { label: 'NorthEast', value: 'NorthEast' },
      ],
    },
    'What is the current average hourly rate you are paying for technicians?': {
      title:
        'What is the current average hourly rate you are paying your technicians?',
      type: 'input',
      validate: 'number',
    },
  },
};

export const LABOR_QUESTIONS = [
  Q1_LABOR_DATA,
  Q2_LABOR_DATA,
  Q3_LABOR_DATA,
  Q4_LABOR_DATA,
  Q5_LABOR_DATA,
  Q6_LABOR_DATA,
  Q7_LABOR_DATA,
];
