import { useEffect, useMemo, useState } from 'react';
import { Form, type FormProps, Typography } from 'antd';
import { AxiosError } from 'axios';

import { FormInput } from '../../../components/FormInput/FormInput';
import { INPUT_TYPES } from '../../../shared/enums/common';
import { getFieldsErrors } from '../../../shared/utils/getFieldsErrors';
import { CONFIG } from './form-config';
import S from './style.module.css';
import { MainButton, SelectLabeled } from '../../../components';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import * as authSelectors from '../../../stores/user/selectors';
import { actions as alertActions } from '../../../stores/alert/slice';
import { FLEET_TYPE_OPTIONS } from '../../../shared/const/common';

interface Props {
  formMode: 'create' | 'edit';
  onSubmit: (values: any) => void;
}

export const RegisterForm: React.FC<Props & FormProps> = ({
  formMode,
  onSubmit,
  ...props
}) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const isEditForm = formMode === 'edit';
  const userData = useAppSelector(authSelectors.getUserData);

  const initialValues = useMemo(
    () => ({
      create: {
        agreement: true,
      },
      edit: {
        username: userData?.username,
        name: userData?.name,
        companyName: userData?.companyName,
        fleetType: userData?.fleetType,
      },
    }),
    [userData],
  );
  useEffect(() => {
    form.setFieldsValue(isEditForm ? initialValues.edit : initialValues.create);
  }, [form, initialValues, isEditForm]);

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);

      await onSubmit(values);

      setLoading(false);

      if (!isEditForm) return;

      dispatch(
        alertActions.addAlert({
          message: 'Profile has been edited succesfully',
          type: 'success',
        }),
      );
    } catch (error) {
      setLoading(false);
      const fieldsErrors = getFieldsErrors(
        error as AxiosError<Record<string, string[]>>,
      );
      if (!fieldsErrors) return;
      form.setFields(fieldsErrors);

      dispatch(
        alertActions.addAlert({
          message: isEditForm
            ? 'Profile editing has been failed'
            : 'Registration has been failed',
          type: 'error',
          status: error instanceof AxiosError ? error?.response?.status : '',
        }),
      );
    }
  };

  return (
    <Form form={form} requiredMark={false} onFinish={handleSubmit} {...props}>
      <div className={isEditForm ? S.editContainer : S.createContainer}>
        <FormInput
          label="Name*"
          name="name"
          placeholder="Enter your name"
          rules={CONFIG.required}
          className={isEditForm ? S.input : ''}
        />
        <FormInput
          label="Email*"
          name="username"
          placeholder="Enter your email"
          rules={CONFIG.username}
          disabled={isEditForm}
          className={isEditForm ? S.input : ''}
        />
        <FormInput
          label="Сompany name*"
          name="companyName"
          placeholder="Enter your company name"
          type={INPUT_TYPES.TEXT}
          rules={CONFIG.company_name}
          className={isEditForm ? S.input : ''}
        />
        <SelectLabeled
          name="fleetType"
          options={FLEET_TYPE_OPTIONS}
          className={isEditForm ? S.input : ''}
          formItemProps={{ rules: CONFIG.required }}
          placeholder={'Choose fleet type'}
          label={<Typography.Text type="secondary">Fleet type</Typography.Text>}
        />

        {!isEditForm && (
          <>
            <FormInput
              label="Password*"
              name="password"
              placeholder="Enter your Password"
              type={INPUT_TYPES.PASSWORD}
              rules={CONFIG.password}
            />

            <FormInput
              label="Confirm Password*"
              name="confirm_password"
              placeholder="Confirm your Password"
              type={INPUT_TYPES.PASSWORD}
              rules={CONFIG.confirm_password}
            />
          </>
        )}

        {!isEditForm && (
          <FormInput
            label="Agree to the Terms and Conditions"
            name="agreement"
            type={INPUT_TYPES.CHECKBOX}
            rules={CONFIG.agreement}
            defaultChecked
            valuePropName="checked"
          />
        )}
      </div>

      <MainButton
        loading={loading}
        disabled={loading}
        className={isEditForm ? S.editButton : ''}
      >
        {isEditForm ? 'Submit' : 'Sign Up'}
      </MainButton>
    </Form>
  );
};
