import { MAIN_COLOR, MAIN_COLOR_OPACITY_30 } from '../const/envVariables';

export const mainTheme = {
  token: {
    colorPrimary: MAIN_COLOR || '#D31E25',
    fontFamily: 'Open Sans',
  },
  components: {
    Button: {
      colorBorder: MAIN_COLOR || '#D31E25',
      colorText: MAIN_COLOR || '#D31E25',
      colorLink: MAIN_COLOR || '#D31E25',
      colorLinkHover: MAIN_COLOR || '#D31E25',
      borderRadiusLG: 32,
      colorLinkActive: MAIN_COLOR_OPACITY_30 || '#D31E25',
    },
    Table: {
      headerBg: '#fff',
    },
    Input: {
      paddingBlock: 6,
      borderRadius: 12,
    },
    Select: {
      controlHeight: 36,
      borderRadius: 12,
    },
    Layout: {
      colorBgBase: '#F1F2F5',
      headerBg: '#fff',
    },
    Typography: {
      fontWeightStrong: 700,
    },
    Spin: {
      colorBgBase: MAIN_COLOR || '#D31E25',
    },
    Menu: {
      colorBgContainer: '#F1F2F5',
      controlItemBgActive: '#FFF',
    },
    Alert: {
      colorSuccessBg: '#fff',
      colorSuccessBorder: '#fff',
      colorErrorBg: '#fff',
      colorErrorBorder: '#fff',
    },
    Radio: {
      fontSizeLG: 20,
      borderRadiusLG: 24,
      lineWidth: 0,
    },
  },
};
