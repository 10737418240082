export const Q1_MAINTENANCE_DATA = {
  id: 27,
  question:
    'How does my average maintenance cost per mile compare to the national average?',
  inputs: {
    // 'Fleet type': {
    //   title:
    //     'Great question. Before we get into it, what type of fleet do you have?',
    //   type: 'radio',
    //   options: [
    //     { label: 'Truckload - national', value: 'Truckload - national' },
    //     { label: 'Truckload - regional', value: 'Truckload - regional' },
    //     { label: 'LTL - national', value: 'LTL - national' },
    //     { label: 'LTL - regional', value: 'LTL - regional' },
    //     { label: 'Private', value: 'Private' },
    //     { label: 'Specialty/Other', value: 'Specialty/Other' },
    //   ],
    // },
    // 'What is your title?': {
    //   title: 'Got it. Next, what is your title?',
    //   type: 'input',
    // },
    'How many trucks does your fleet have?': {
      title: 'How many trucks does your fleet have?',
      type: 'input',
      validate: 'number',
    },
    'What type of vehicles does your fleet have?': {
      title: 'Super. Now, what type of vehicles does your fleet have?',
      type: 'radio',
      options: [
        { label: 'Class 8 tractors mostly', value: 'Class 8 tractors mostly' },
        {
          label: 'Class 8 straight trucks mostly',
          value: 'Class 8 straight trucks mostly',
        },
        { label: 'Class 5-7 trucks mostly', value: 'Class 5-7 trucks mostly' },
        { label: 'Class 2-4 trucks mostly', value: 'Class 2-4 trucks mostly' },
      ],
    },
    'What is your fleet’s current average maintenance cost per mile?': {
      title:
        'Thanks. What is your fleet’s current average maintenance cost per mile? (enter dollar value)',
      type: 'input',
      validate: 'number',
    },
  },
};

export const Q2_MAINTENANCE_DATA = {
  id: 28,
  question: 'How does my average truck age compare to the national average?',
  inputs: {
    // 'What type of fleet do you have?': {
    //   title: 'A very good question. First, tell us about your fleet?',
    //   type: 'radio',
    //   options: [
    //     { label: 'Truckload - national', value: 'Truckload - national' },
    //     { label: 'Truckload - regional', value: 'Truckload - regional' },
    //     { label: 'LTL - national', value: 'LTL - national' },
    //     { label: 'LTL - regional', value: 'LTL - regional' },
    //     { label: 'Private', value: 'Private' },
    //     { label: 'Specialty/Other', value: 'Specialty/Other' },
    //   ],
    // },
    // 'What is your title?': {
    //   title: 'Got it. Next, what is your title?',
    //   type: 'input',
    // },
    'What is the average mileage on trucks you are trading in?': {
      title: 'What is the average mileage on trucks you are trading in?',
      type: 'input',
      validate: 'number',
    },
    'What is the average age of trucks you traded in over the past year?': {
      title:
        'Thanks. Now can you tell me the average age of trucks you traded in over the past year? (enter value in years)',
      type: 'input',
    },
    'What is the average age of trucks in your fleet currently?': {
      title:
        'Perfect. Lastly, what is the average age of trucks in your fleet currently? (enter value in years).',
      type: 'input',
      validate: 'number',
    },
  },
};

export const MAINTENANCE_QUESTIONS = [Q1_MAINTENANCE_DATA, Q2_MAINTENANCE_DATA];
