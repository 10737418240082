import { Typography, Flex, Image as AntdImage } from 'antd';
import React from 'react';
import 'chartjs-adapter-date-fns';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  ChartOptions,
  Ticks,
} from 'chart.js';
import predictionIcon from '../../../../assets/prediction-icon.svg';
import { ICompensationChartData } from '../../../../types/compensation';
import S from './style.module.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
);

const chartOptions: ChartOptions<'line'> = {
  responsive: true,
  aspectRatio: 4 / 1,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      boxPadding: 4,
      callbacks: {
        title: function () {
          return '';
        },
        label: function (context: any) {
          return context.parsed.y;
        },
      },
    },
  },
  scales: {
    y: {
      ticks: {
        callback: function (value: any, index, ticks) {
          return (
            Ticks.formatters.numeric.apply(this, [value, index, ticks]) + ' ($)'
          );
        },
      },
    },
    x: {
      type: 'time',
      time: {
        unit: 'day',
        displayFormats: {
          day: 'MMM dd, yyyy',
        },
      },
      ticks: {
        source: 'data',
      },
      grid: {
        display: false,
      },
    },
  },
};

const customChartPoint = new Image();
customChartPoint.src = predictionIcon;

interface Props {
  data: ICompensationChartData;
  selectedLabelApplicationType?: string;
}

export const CompensationChart = ({
  data,
  selectedLabelApplicationType,
}: Props) => {
  const defaultColor = 'rgba(211, 30, 37, 1)';
  const specialColor = 'rgba(242, 153, 74, 1)';

  const chartData = {
    datasets: [
      {
        data: data.map((item) => ({
          x: item.x,
          y: item.y,
        })),
        borderColor: (context: any) => {
          const index = context.dataIndex;
          if (
            index < data.length &&
            data[index] &&
            typeof data[index].isGenerated !== 'undefined'
          ) {
            return data[index].isGenerated ? specialColor : defaultColor;
          }
          return defaultColor;
        },
        backgroundColor: data.map((item, index) => {
          if (index === data.length - 1 || index === data.length - 2) {
            return specialColor;
          }
          return item.isGenerated ? specialColor : defaultColor;
        }),
        tension: 0.3,
        borderWidth: 4,
        pointBorderWidth: 6,
        pointBackgroundColor: data.map((item) => {
          return item.isGenerated ? specialColor : defaultColor;
        }),
        pointStyle: data.map((item) =>
          item.isGenerated ? customChartPoint : 'circle',
        ),
        segment: {
          borderColor: (context: any) => {
            const index = context.p0DataIndex;
            if (
              data[data.length - 1].isGenerated &&
              index === data.length - 2
            ) {
              return specialColor;
            }
            if (
              index < data.length &&
              data[index] &&
              typeof data[index].isGenerated !== 'undefined'
            ) {
              return data[index].isGenerated ? specialColor : defaultColor;
            }
            return defaultColor;
          },
        },
      },
    ],
  };

  return (
    <>
      <Flex align="center" justify="space-between" className={S.titleWrap}>
        {selectedLabelApplicationType && (
          <Typography.Title level={4} className={S.title}>
            {selectedLabelApplicationType}
          </Typography.Title>
        )}

        <Flex gap="10px" align="center" className={S.predictionWrap}>
          <AntdImage preview={false} src={predictionIcon} />
          <Typography.Text className={S.predictionLabel}>
            Prediction
          </Typography.Text>
        </Flex>
      </Flex>
      <div className={S.chartContainer}>
        <div style={{ minWidth: 1024 }}>
          <Line options={chartOptions} data={chartData} />
        </div>
      </div>
    </>
  );
};
