import { IndexType } from '../../../../types/analitics';
import { Table, TableProps } from 'antd';
import { useMemo } from 'react';
import S from './style.module.css';

interface MoreInfoDesktopTableProps {
  data: IndexType[];
  getInputNumber: (value: any, title: string) => JSX.Element;
}

export const MoreInfoMobileTable = ({
  data,
  getInputNumber,
}: MoreInfoDesktopTableProps) => {
  const getColumns = (title: string): TableProps['columns'] => [
    {
      title: () => <span className={S.tableTitle}>{title}</span>,
      colSpan: 2,
      dataIndex: 'title',
      render: (value) => <span className={S.tableSubTitle}>{value}</span>,
    },
    {
      title: 'Value',
      colSpan: 0,
      dataIndex: 'value',
      render: (value, record, index) => {
        const weight = data.find((i) => i.title === title)?.weight;

        if (index === 1 && weight !== undefined) {
          return `${value} x ${weight / 100}`;
        }

        return getInputNumber(value, title);
      },
    },
  ];

  const mappedData = useMemo(
    () =>
      data.map(({ title, weight, calculation }) => ({
        title,
        data: [
          {
            title: 'Weighting',
            value: weight,
          },
          {
            title: 'Component Calculation',
            value: calculation,
          },
        ],
      })),
    [data],
  );

  return (
    <div className={S.tablesWrapper}>
      {mappedData.map(({ title, data }) => (
        <Table
          key={title}
          bordered
          dataSource={data}
          columns={getColumns(title)}
          pagination={false}
        />
      ))}
    </div>
  );
};
