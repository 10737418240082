import { Link } from 'react-router-dom';
import { Typography } from 'antd';

import Labor from '../../assets/images/labor.png';
import Fuel from '../../assets/images/fuel.png';
import Safety from '../../assets/images/safety.png';
import Financial from '../../assets/images/financials.png';
import Maintenance from '../../assets/images/maintenance.png';
import { Paths } from '../../shared/const/route-paths';
import { MainButton } from '../../components';
import { Logo } from './../../components/Logo/Logo';
import { TextInfoBlock } from './../../components/TextInfoBlock';
import smallLogo from '../../assets/small-logo.svg';
import S from './styles/assistant.module.css';

export const Assistant = () => {
  const TOPICS = [
    {
      id: 1,
      title: 'labor',
      image: Labor,
      button: 'Labor',
      path: Paths.APP.ASSISTANT.LABOR,
    },
    {
      id: 2,
      title: 'fuel',
      image: Fuel,
      button: 'Fuel',
      path: Paths.APP.ASSISTANT.FUEL,
    },
    {
      id: 3,
      title: 'safety',
      image: Safety,
      button: 'Safety',
      path: Paths.APP.ASSISTANT.SAFETY,
    },
    {
      id: 4,
      title: 'maintenance',
      image: Maintenance,
      button: 'Maintenance',
      path: Paths.APP.ASSISTANT.MAINTENANCE,
    },
    {
      id: 5,
      title: 'financial',
      image: Financial,
      button: 'Financial Performance',
      path: Paths.APP.ASSISTANT.FINANCIAL,
    },
  ];

  return (
    <>
      <TextInfoBlock>
        <Logo style={{ width: 'auto' }} src={smallLogo} />
        <Typography.Title level={1} style={{ margin: '24px 0 50px' }}>
          Welcome to the TruckAnswers Assistant
        </Typography.Title>
      </TextInfoBlock>
      <div>
        <Typography.Title level={2} style={{ marginBottom: 40 }}>
          Select a topic:
        </Typography.Title>
        <div className={S.container}>
          {TOPICS.map((topic) => (
            <div key={topic.id} className={S.topicContainer}>
              <img src={topic.image} alt={topic.title} className={S.image} />

              <Link to={topic.path}>
                <MainButton
                  type="default"
                  htmlType="button"
                  className={S.topicButton}
                >
                  {topic.button}
                </MainButton>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Assistant;
