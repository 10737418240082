import { Link, useParams } from 'react-router-dom';
import { Typography } from 'antd';

import { TextInfoBlock } from '../../components/TextInfoBlock';
import { MainButton } from '../../components';
import { topicInfo } from '../../shared/const/topicInfo';
import Logo from '../../assets/logo.svg';
import S from './styles/topic.module.css';

export const AssistantTopic = () => {
  const { topic = 'labor' } = useParams();

  return (
    <div className={S.container}>
      <div className="flex-[1_0_auto]">
        <TextInfoBlock>
          <div className={S.titleContainer}>
            <img src={Logo} alt="Logo" style={{ width: 314, height: 49 }} />
            <Typography.Title level={1} style={{ margin: 0 }}>
              Assistant
            </Typography.Title>
          </div>

          <Typography.Title level={2} style={{ marginTop: 24 }}>
            {topicInfo[topic]?.title}
          </Typography.Title>
        </TextInfoBlock>
      </div>

      <div style={{ marginTop: 40, marginBottom: 80 }}>
        <Typography.Title level={2}>
          {topicInfo[topic]?.subtitle}
        </Typography.Title>

        <div className={S.questions}>
          {topicInfo[topic] &&
            topicInfo[topic].questions.map(({ id, text }) => (
              <Link to={`${id}`} key={id}>
                <MainButton
                  htmlType="button"
                  type="default"
                  className={S.topicButton}
                >
                  {text}
                </MainButton>
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

export default AssistantTopic;
