import { Typography, Form, Spin } from 'antd';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { SelectOption } from '../../types/common';
import {
  ICompensationChartData,
  RequestParams,
} from '../../types/compensation';
import { useAppDispatch } from '../../hooks/hooks';
import { CompensationService } from '../../services/compensation/api';
import { actions as alertActions } from '../../stores/alert/slice';
import { SelectLabeled } from '../../components';
import { CompensationChart } from './components/CompensationChart';
import S from './style.module.css';

export const DriverCompensation = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [fetchData, setFetchData] = useState<ICompensationChartData>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [applicationTypesOptions, setApplicationTypesOptions] = useState<
    SelectOption[]
  >([]);
  const [compensationTypesOptions, setCompensationTypesOptions] = useState<
    SelectOption[]
  >([]);

  const [regionsOptions, setRegionsOptions] = useState<SelectOption[]>([]);

  const getChartData = async (params: RequestParams) => {
    setLoading(true);

    try {
      const { data } = await CompensationService.getDataset(params);

      setFetchData(data);
    } catch (error) {
      dispatch(
        alertActions.addAlert({
          message: 'Data fetching has been failed',
          type: 'error',
          status: error instanceof AxiosError ? error.response?.status : '',
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const getApplicationTypesOptions = async () => {
    try {
      const { data } = await CompensationService.getApplicationTypes();

      setApplicationTypesOptions(data);
      if (data.length > 0) {
        form.setFieldValue('application_type', data[0].value);
      }
    } catch (error) {
      dispatch(
        alertActions.addAlert({
          message: 'Data fetching of application types has been failed',
          type: 'error',
          status: error instanceof AxiosError ? error.response?.status : '',
        }),
      );
    }
  };

  const getCompensationTypesOptions = async () => {
    try {
      const { data } = await CompensationService.getCompensationTypes();

      setCompensationTypesOptions(data);
      if (data.length > 0) {
        form.setFieldValue('compensation_type', data[0].value);
      }
    } catch (error) {
      dispatch(
        alertActions.addAlert({
          message: 'Data fetching of compensation types has been failed',
          type: 'error',
          status: error instanceof AxiosError ? error.response?.status : '',
        }),
      );
    }
  };

  const getRegionsOptions = async () => {
    try {
      const { data } = await CompensationService.getRegions();

      setRegionsOptions(data);
      if (data.length > 0) {
        form.setFieldValue('region', data[0].value);
      }
    } catch (error) {
      dispatch(
        alertActions.addAlert({
          message: 'Data fetching of compensation types has been failed',
          type: 'error',
          status: error instanceof AxiosError ? error.response?.status : '',
        }),
      );
    }
  };

  const getInitialData = async () => {
    setLoading(true);
    try {
      await Promise.all([
        getApplicationTypesOptions(),
        getCompensationTypesOptions(),
        getRegionsOptions(),
      ]);

      const fieldsValue = form.getFieldsValue();

      await getChartData(fieldsValue);
    } catch (error) {
      dispatch(
        alertActions.addAlert({
          message: 'Error in fetching compensation initial data',
          type: 'error',
          status: error instanceof AxiosError ? error.response?.status : '',
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const handleChange = async () => {
    const fieldsValue = form.getFieldsValue();
    await getChartData(fieldsValue);
  };

  const selectedApplicationType = form.getFieldValue('application_type');
  const selectedLabelApplicationType = applicationTypesOptions.find(
    (option) => option.value === selectedApplicationType,
  )?.label;

  return (
    <div className={S.container}>
      <Typography.Title level={1}>Driver Compensation</Typography.Title>
      <Spin spinning={loading}>
        <div className={S.editContainer}>
          <Form form={form} className={S.form} layout="vertical">
            <SelectLabeled
              name="application_type"
              options={applicationTypesOptions}
              onChange={handleChange}
              label={
                <Typography.Text type="secondary">
                  Application Type
                </Typography.Text>
              }
            />
            <SelectLabeled
              name="compensation_type"
              options={compensationTypesOptions}
              onChange={handleChange}
              label={
                <Typography.Text type="secondary">
                  Compensation Type
                </Typography.Text>
              }
            />
            <SelectLabeled
              name="region"
              options={regionsOptions}
              onChange={handleChange}
              label={<Typography.Text type="secondary">Region</Typography.Text>}
            />
          </Form>
        </div>
        <div style={{ width: '100%', overflow: 'auto' }}>
          <CompensationChart
            data={fetchData}
            selectedLabelApplicationType={selectedLabelApplicationType}
          />
        </div>
      </Spin>
    </div>
  );
};
