import { type AxiosResponse } from 'axios';
import { ENDPOINTS } from '../../shared/const/endpoints';
import makeRequest from '../../shared/utils/makeRequest';
import { SelectOption } from '../../types/common';
import {
  ICompensationChartData,
  RequestParams,
} from '../..//types/compensation';

export class CompensationService {
  static async getDataset(
    params?: RequestParams,
  ): Promise<AxiosResponse<ICompensationChartData>> {
    return makeRequest({
      method: 'get',
      url: ENDPOINTS.COMPENSATION_DATASET,
      params,
    });
  }

  static async getApplicationTypes(): Promise<AxiosResponse<SelectOption[]>> {
    return makeRequest({
      method: 'get',
      url: ENDPOINTS.COMPENSATION_APPLICATION_TYPES,
    });
  }

  static async getCompensationTypes(): Promise<AxiosResponse<SelectOption[]>> {
    return makeRequest({
      method: 'get',
      url: ENDPOINTS.COMPENSATION_COMPENSATION_TYPES,
    });
  }

  static async getRegions(): Promise<AxiosResponse<SelectOption[]>> {
    return makeRequest({
      method: 'get',
      url: ENDPOINTS.COMPENSATION_REGIONS,
    });
  }
}
