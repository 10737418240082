export const Q1_SAFETY_DATA = {
  id: 1,
  question: 'How do my truck insurance costs compare to other fleets?',
  inputs: {
    // 'Fleet type': {
    //   title:
    //     'A really good question! First, tell us what type of fleet you have',
    //   type: 'radio',
    //   options: [
    //     { label: 'Truckload - national', value: 'Truckload - national' },
    //     { label: 'Truckload - regional', value: 'Truckload - regional' },
    //     { label: 'LTL - national', value: 'LTL - national' },
    //     { label: 'LTL - regional', value: 'LTL - regional' },
    //     { label: 'Private', value: 'Private' },
    //     { label: 'Specialty/Other', value: 'Specialty/Other' },
    //   ],
    // },
    // 'What is your title?': {
    //   title: 'Got it. Next, what is your title? ',
    //   type: 'input',
    // },
    'Number of trucks': {
      title: 'How many trucks are currently in your fleet?',
      type: 'input',
      validate: 'number',
    },
    'Average annual mileage per truck': {
      title: 'What is the average annual mileage per truck in your fleet?',
      type: 'input',
      validate: 'number',
    },
    'Current driver turnover rate': {
      title:
        'Super. And what is your current annual driver turnover rate? (Enter percentage value)',
      type: 'input',
      validate: 'number',
    },
    'Total annual truck liability insurance costs': {
      title:
        'Almost done. What is your total annual truck insurance cost? (Enter dollar value)',
      type: 'input',
      validate: 'number',
    },
  },
};

export const Q2_SAFETY_DATA = {
  id: 2,
  question: 'How do my driver safety bonuses compare to other fleets?',
  inputs: {
    // 'What type of fleet do you have?': {
    //   title:
    //     'That’s a great question on an important topic. Before we answer, tell us what type of fleet you have.',
    //   type: 'radio',
    //   options: [
    //     { label: 'Truckload - national', value: 'Truckload - national' },
    //     { label: 'Truckload - regional', value: 'Truckload - regional' },
    //     { label: 'LTL - national', value: 'LTL - national' },
    //     { label: 'LTL - regional', value: 'LTL - regional' },
    //     { label: 'Private', value: 'Private' },
    //     { label: 'Specialty/Other', value: 'Specialty/Other' },
    //   ],
    // },
    // 'What is your title?': {
    //   title: 'Got it. Next, what is your title?',
    //   type: 'input',
    // },
    'Average annual mileage per truck': {
      title: 'What is your average annual mileage per truck?',
      type: 'input',
      validate: 'number',
    },
    'Paying driver safety bonuses now?': {
      title: 'Almost there. Are you paying driver safety bonuses now?',
      type: 'radio',
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
    },
    'Current annual spend on driver safety bonuses': {
      title:
        'What is your current annual spend on safety bonuses per driver? (Enter dollar value)',
      type: 'input',
      validate: 'number',
    },
  },
};

export const Q3_SAFETY_DATA = {
  id: 3,
  question:
    'How big of a safety issue is distracted driving and what can I do to reduce the risk?',
  inputs: {
    // 'What type of fleet do you have?': {
    //   title:
    //     'That’s a great question on an important topic. Before we answer, tell us what type of fleet you have.',
    //   type: 'radio',
    //   options: [
    //     { label: 'Truckload - national', value: 'Truckload - national' },
    //     { label: 'Truckload - regional', value: 'Truckload - regional' },
    //     { label: 'LTL - national', value: 'LTL - national' },
    //     { label: 'LTL - regional', value: 'LTL - regional' },
    //     { label: 'Private', value: 'Private' },
    //     { label: 'Specialty/Other', value: 'Specialty/Other' },
    //   ],
    // },
    // 'What is your title': {
    //   title: 'Got it. Next, what is your title?',
    //   type: 'input',
    // },
    'How many trucks do you have currently?': {
      title: 'How many trucks are currently in your fleet?',
      type: 'input',
      validate: 'number',
    },
    'What is your current annual driver turnover rate?': {
      title:
        'Thank you. Now, what is your current annual driver turnover rate? (Enter percentage value)',
      type: 'input',
      validate: 'number',
    },
    'Do you currently restrict cell phone use by drivers?': {
      title: 'Super. Do you currently restrict cell phone use by drivers?',
      type: 'radio',
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
    },
    'What is your primary method of communication with drivers while they are on the road?':
      {
        title:
          'Finally, what is your primary method of communication with drivers while they are on the road?',
        type: 'radio',
        options: [
          { label: 'Phone calls.', value: 'Phone calls.' },
          { label: 'Emails.', value: 'Emails.' },
          {
            label: 'Texting on personal cell phones.',
            value: 'Texting on personal cell phones.',
          },
          {
            label: 'Messaging through TMS/telematics system.',
            value: 'Messaging through TMS/telematics system.',
          },
        ],
      },
  },
};

export const Q4_SAFETY_DATA = {
  id: 4,
  question:
    'How does my total cost of risk management compare to fleets my size?',
  inputs: {
    // 'What type of fleet do you have?': {
    //   title:
    //     'Great question. Before we answer, tell us about the type of fleet you have',
    //   type: 'radio',
    //   options: [
    //     { label: 'Truckload - national', value: 'Truckload - national' },
    //     { label: 'Truckload - regional', value: 'Truckload - regional' },
    //     { label: 'LTL - national', value: 'LTL - national' },
    //     { label: 'LTL - regional', value: 'LTL - regional' },
    //     { label: 'Private', value: 'Private' },
    //     { label: 'Specialty/Other', value: 'Specialty/Other' },
    //   ],
    // },
    // 'What is your title': {
    //   title: 'Got it. Next, what is your title?',
    //   type: 'input',
    // },
    'How large your fleet is?': {
      title: 'How large is your fleet?',
      type: 'radio',
      options: [
        { label: 'Less than 26 trucks', value: 'Less than 26 trucks' },
        { label: '26 to 100 trucks', value: '26 to 100 trucks' },
        { label: '101-250 trucks', value: '101-250 trucks' },
        { label: '251 to 1,000 trucks', value: '251 to 1,000 trucks' },
        { label: 'Over 1,000 trucks', value: 'Over 1,000 trucks' },
      ],
    },
    'What is your current total cost of risk management per mile?': {
      title:
        'Finally, what is your current total cost of risk management per mile (anything spent out-of-pocket on safety except insurance premiums)? (enter cents per mile)',
      type: 'input',
      validate: 'number',
    },
  },
};

export const Q5_SAFETY_DATA = {
  id: 5,
  question:
    'How does my fleet’s out-of-service rate over the last two years compare to the national average?',
  inputs: {
    'What your fleet’s out-of-service rate is over the most recent two years?':
      {
        title:
          'What is your fleet’s out-of-service rate over the most recent two years (available on the SAFER database) (percentage numeric entry)?',
        type: 'input',
        validate: 'number',
      },
  },
};

export const Q6_SAFETY_DATA = {
  id: 6,
  question:
    'How does my fleet’s accident rate per million miles over the last two years compare to the national average?',
  inputs: {
    'What your fleet’s accident rate per million miles has been over the most recent two years':
      {
        title:
          'What is your fleet’s accident rate per million miles over the most recent two years? (available on the SAFER database) (numeric entry)',
        type: 'input',
        validate: 'number',
      },
  },
};

export const SAFETY_QUESTIONS = [
  Q1_SAFETY_DATA,
  Q2_SAFETY_DATA,
  Q3_SAFETY_DATA,
  Q4_SAFETY_DATA,
  Q5_SAFETY_DATA,
  Q6_SAFETY_DATA,
];
