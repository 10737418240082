export const ENDPOINTS = {
  SIGN_UP: '/api/auth/users/',
  LOG_IN: '/api/auth/jwt/create/',
  REFRESH_TOKEN: '/api/auth/jwt/refresh/',
  GET_USER_DATA: '/api/auth/users/me/',
  ACTIVATE_EMAIL: '/api/auth/users/activation/',
  RESET_PASSWORD: '/api/auth/users/reset_password/',
  RESET_PASSWORD_CONFIRM: '/api/auth/users/reset_password_confirm/',
  UPLOAD_FILE: '/api/files/',
  SET_NEW_PASSWORD: '/api/auth/users/set_password/',
  EDIT_USER: '/api/auth/users/',
  VERIFY_TOKEN: '/api/auth/jwt/verify/',
  GET_DATASETS: '/api/analytics/charts/',
  GET_WEIGHTS: '/api/analytics/weights/',
  GET_DEFAULT_WEIGHTS: '/api/analytics/weights/defaults/',
  GENERATE_ANSWER: '/api/v1/generate/',
  COMPENSATION_DATASET: '/api/compensations/',
  COMPENSATION_APPLICATION_TYPES: '/api/compensations/application_types/',
  COMPENSATION_COMPENSATION_TYPES: '/api/compensations/compensation_types/',
  COMPENSATION_REGIONS: '/api/compensations/regions/',
};
