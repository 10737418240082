import { Modal, Typography, InputNumber } from 'antd';
import { useState, useEffect, useCallback } from 'react';

import { MainButton } from '../../../../components';
import { IModal, IndexType, IWeight } from '../../../../types/analitics';
import S from './style.module.css';
import { useWindowSize } from '../../../../hooks/useWindowResize';
import { MoreInfoDesktopTable } from './MoreInfoDesktopTable';
import { MoreInfoMobileTable } from './MoreInfoMobileTable';
import { actions as alertActions } from '../../../../stores/alert/slice';
import { AxiosError } from 'axios';
import { useAppDispatch } from '../../../../hooks/hooks';

export const MoreInfoModal = ({
  weights,
  defaultWeights,
  topic,
  modalOpened,
  setModalOpened,
  setWeights,
}: IModal) => {
  const dispatch = useAppDispatch();
  const [windowWidth] = useWindowSize();
  const [data, setData] = useState<IndexType[]>([]);

  const isMobile = windowWidth <= 425;

  const mapData = useCallback((weights: IWeight[]) => {
    const mappedData: IndexType[] = weights
      .filter((weight) => weight.topic === topic)
      .map(({ indexName, value, fleetType }) => {
        const newName = indexName.split('_').slice(2).join(' ');

        return {
          indexName,
          weight: value,
          title: `Average ${newName}`,
          calculation: `Percentage change in ${newName} in previous month`,
          fleetType,
        };
      });

    setData(mappedData);
  }, []);

  useEffect(() => {
    mapData(weights);
    // eslint-disable-next-line
  }, []);

  const getInputNumber = (value: any, title: string) => (
    <InputNumber
      value={value}
      onChange={(e) => onWeightChange(e, title)}
      formatter={(value) => `${value}%`}
      parser={(value) => value?.replace('%', '') as unknown as number}
      controls={false}
      max={100}
      min={0}
    />
  );

  const onWeightChange = (value: number, title: string) => {
    const newData = [...data];
    const changedItem = newData.find((item) => item.title === title);

    if (!changedItem) return;

    changedItem.weight = value;

    setData(newData);
  };

  const setToDefault = () => mapData(defaultWeights);

  const onClose = () => {
    setModalOpened(false);
  };

  const getMappedWeights = () => {
    const filteredWeights = weights.filter((weight) => weight.topic !== topic);
    const mappedWeights: IWeight[] = data.map(
      ({ indexName, weight, fleetType }) => ({
        value: weight,
        indexName,
        topic,
        fleetType,
      }),
    );

    return [...filteredWeights, ...mappedWeights];
  };

  const onSave = async () => {
    const mappedWeights = getMappedWeights();

    try {
      await setWeights(mappedWeights);

      onClose();
      dispatch(
        alertActions.addAlert({
          message: 'New weights have been applied',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        alertActions.addAlert({
          message: 'Data fetching has been failed',
          type: 'error',
          status: error instanceof AxiosError ? error.response?.status : '',
        }),
      );
    }
  };

  const isSaveButtonDisabled =
    data.reduce(
      (previousValue, currentValue) => previousValue + currentValue.weight,
      0,
    ) !== 100;

  const mobileButtonStyle = isMobile ? { width: '100%' } : undefined;

  return (
    <Modal
      width={900}
      centered
      open={modalOpened}
      onCancel={onClose}
      styles={{
        header: {
          textAlign: 'center',
          textTransform: 'capitalize',
        },
        body: {
          overflow: 'auto',
        },
      }}
      title={
        <Typography.Title level={2} className={S.modalTitle}>
          National Trucking Cost Index - {topic} Components
        </Typography.Title>
      }
      footer={() => (
        <div className={S.footer}>
          <MainButton
            className={S.footerBtn}
            key="default"
            type="default"
            onClick={setToDefault}
            style={mobileButtonStyle}
          >
            Set To Default
          </MainButton>
          <MainButton
            className={S.footerBtn}
            key="save"
            onClick={onSave}
            disabled={isSaveButtonDisabled}
            style={mobileButtonStyle}
          >
            Save
          </MainButton>
        </div>
      )}
    >
      <Typography.Paragraph strong className={S.description}>
        The {topic} components of the NTCI are weighted as follows:
      </Typography.Paragraph>
      {isMobile ? (
        <MoreInfoMobileTable data={data} getInputNumber={getInputNumber} />
      ) : (
        <MoreInfoDesktopTable data={data} getInputNumber={getInputNumber} />
      )}
    </Modal>
  );
};
