import { ITopicsMap } from './../../types/common';

export const topicInfo: ITopicsMap = {
  safety: {
    title: 'Safety Questions',
    subtitle: 'Select one of the safety-related questions below:',
    questions: [
      {
        id: 1,
        text: 'How do my truck insurance costs compare to other fleets?',
      },
      {
        id: 2,
        text: 'How do my driver safety bonuses compare to other fleets?',
      },
      {
        id: 3,
        text: 'How big of a safety issue is distracted driving and what can I do to reduce the risk?',
      },
      {
        id: 4,
        text: 'How does my total cost of risk management compare to fleets my size?',
      },
      {
        id: 5,
        text: 'How does my fleet’s out-of-service rate over the last two years compare to the national average?',
      },
      {
        id: 6,
        text: 'How does my fleet’s accident rate per million miles over the last two years compare to the national average?',
      },
    ],
  },
  fuel: {
    title: 'Fuel Questions',
    subtitle: 'Select one of the fuel-related questions below:',
    questions: [
      {
        id: 7,
        text: 'What is the current long-term trend in crude oil pricing and how will that affect future diesel prices?',
      },
      {
        id: 8,
        text: 'How does my fleet fuel economy compare to the current national average?',
      },
      {
        id: 9,
        text: 'What are the keys to a successful driver fuel bonus program at my fleet?',
      },
      {
        id: 10,
        text: 'What are my requirements for fuel tax reporting?',
      },
      {
        id: 11,
        text: 'How can I catch fuel theft in my fleet?',
      },
      {
        id: 12,
        text: 'Summarize all of the current data on driver-related impact on fuel economy.',
      },
      {
        id: 13,
        text: 'How does my speed governing policy compare to other fleets?',
      },
      {
        id: 14,
        text: 'How does my fuel cost per mile compare to the national average?',
      },
      {
        id: 15,
        text: 'How does the price I’m currently paying for diesel compare to the national average?',
      },
      {
        id: 16,
        text: 'How does my deadhead mileage compare to fleets like mine?',
      },
    ],
  },
  labor: {
    title: 'Labor Questions',
    subtitle: 'Select one of the labor-related questions below:',
    questions: [
      {
        id: 17,
        text: 'How does my driver mileage rate compare to the current national average?',
      },
      {
        id: 18,
        text: 'How does my driver bonus program compare to the current national average?',
      },
      {
        id: 19,
        text: 'How does my driver turnover rate compare to the current national average?',
      },
      {
        id: 20,
        text: 'How do my detention pay and parking compensation policies compare to other fleets?',
      },
      {
        id: 21,
        text: 'How does my driver replacement cost compare to the current national average?',
      },
      {
        id: 22,
        text: 'How does my driver benefits package compare to other fleets?',
      },
      {
        id: 23,
        text: 'How does my technician compensation compare to the national average?',
      },
    ],
  },
  financial: {
    title: 'Financial Questions',
    subtitle: 'Select one of the financial-related questions below:',
    questions: [
      {
        id: 24,
        text: 'How does my operating cost per mile compare to the current national average?',
      },
      {
        id: 25,
        text: 'How does my operating margin compare to the industry average?',
      },
      {
        id: 26,
        text: 'How does my debt-to-equity ratio compare to the industry average?',
      },
    ],
  },
  maintenance: {
    title: 'Maintenance Questions',
    subtitle: 'Select one of the maintenance-related questions below:',
    questions: [
      {
        id: 27,
        text: 'How does my average maintenance cost per mile compare to the national average?',
      },
      {
        id: 28,
        text: 'How does my average truck age compare to the national average?',
      },
    ],
  },
};
