import { SAFETY_QUESTIONS } from './safety';
import { LABOR_QUESTIONS } from './labor';
import { FUEL_QUESTIONS } from './fuel';
import { FINANCIAL_QUESTIONS } from './financial';
import { MAINTENANCE_QUESTIONS } from './maintenance';

export const ALL_QUESTIONS = [
  ...SAFETY_QUESTIONS,
  ...FUEL_QUESTIONS,
  ...LABOR_QUESTIONS,
  ...FINANCIAL_QUESTIONS,
  ...MAINTENANCE_QUESTIONS,
];

export const ALL_QUESTIONS_WITH_KEYS = [
  { key: 'safety', questions: SAFETY_QUESTIONS },
  { key: 'fuel', questions: FUEL_QUESTIONS },
  { key: 'labor', questions: LABOR_QUESTIONS },
  { key: 'financial', questions: FINANCIAL_QUESTIONS },
  { key: 'maintenance', questions: MAINTENANCE_QUESTIONS },
];
