import { Form, Typography, Input, Checkbox } from 'antd';
import type { FormItemProps, Rule } from 'antd/es/form';
import { INPUT_TYPES } from '../../shared/enums/common';
import { useMemo } from 'react';

interface Props {
  name: string;
  label?: string;
  placeholder?: string;
  rules?: Rule[];
  prefix?: React.ReactNode;
  autoSize?: boolean;
  type?: INPUT_TYPES;
  defaultChecked?: boolean;
  inputStyles?: React.CSSProperties;
  valuePropName?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export const FormInput: React.FC<Props & FormItemProps> = ({
  name,
  label,
  rules,
  placeholder,
  prefix,
  autoSize,
  type,
  defaultChecked,
  inputStyles,
  style,
  valuePropName,
  disabled,
  required,
  onBlur,
  onChange,
  ...formItemProps
}) => {
  const inputType = useMemo(() => {
    switch (type) {
      case INPUT_TYPES.PASSWORD:
        return (
          <Input.Password
            style={inputStyles}
            prefix={prefix}
            placeholder={placeholder}
          />
        );

      case INPUT_TYPES.TEXT_AREA:
        return <Input.TextArea style={inputStyles} autoSize={autoSize} />;

      case INPUT_TYPES.CHECKBOX:
        return (
          <Checkbox style={inputStyles} defaultChecked={defaultChecked}>
            {label}
          </Checkbox>
        );

      default:
        return (
          <Input
            style={inputStyles}
            prefix={prefix}
            placeholder={placeholder}
            disabled={disabled}
            onBlur={onBlur}
            onChange={onChange}
          />
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <Form.Item
      name={name}
      label={
        label &&
        type !== 'checkbox' && (
          <Typography.Text type="secondary">{label}</Typography.Text>
        )
      }
      rules={rules}
      valuePropName={valuePropName}
      style={style}
      required={required}
      {...formItemProps}
    >
      {inputType}
    </Form.Item>
  );
};
