export const Q1_FUEL_DATA = {
  id: 7,
  question:
    'What is the current long-term trend in crude oil pricing and how will that affect future diesel prices?',
  inputs: {
    // 'What type of fleet do you have?': {
    //   title:
    //     'Good question. Before I answer, tell me about the type of fleet you have.',
    //   type: 'radio',
    //   options: [
    //     { label: 'Truckload - national', value: 'Truckload - national' },
    //     { label: 'Truckload - regional', value: 'Truckload - regional' },
    //     { label: 'LTL - national', value: 'LTL - national' },
    //     { label: 'LTL - regional', value: 'LTL - regional' },
    //     { label: 'Private', value: 'Private' },
    //     { label: 'Specialty/Other', value: 'Specialty/Other' },
    //   ],
    // },
    // 'What is your title?': {
    //   title: 'Thanks. And what is your title?',
    //   type: 'input',
    // },
    'How many trucks do you have currently?': {
      title: 'How many trucks are currently in your fleet?',
      type: 'input',
      validate: 'number',
    },
    'What is your current average fleet fuel economy?': {
      title:
        'If you have it, what is your current average fleet fuel economy? (Enter MPG value)',
      type: 'input',
      validate: 'number',
    },
    'Are you currently hedging or pre-buying diesel?': {
      title: 'And finally, are you currently hedging or pre-buying diesel?',
      type: 'radio',
      options: [
        { label: 'We are hedging.', value: 'We are hedging.' },
        { label: 'We are pre-buying', value: 'We are pre-buying' },
        { label: 'Neither', value: 'Neither' },
      ],
    },
  },
};

export const Q2_FUEL_DATA = {
  id: 8,
  question:
    'How does my fleet fuel economy compare to the current national average?',
  inputs: {
    // 'What type of fleet do you have?': {
    //   title:
    //     'Good question. Before I answer, tell me about the type of fleet you have.',
    //   type: 'radio',
    //   options: [
    //     { label: 'Truckload - national', value: 'Truckload - national' },
    //     { label: 'Truckload - regional', value: 'Truckload - regional' },
    //     { label: 'LTL - national', value: 'LTL - national' },
    //     { label: 'LTL - regional', value: 'LTL - regional' },
    //     { label: 'Private', value: 'Private' },
    //     { label: 'Specialty/Other', value: 'Specialty/Other' },
    //   ],
    // },
    // 'What is your title?': {
    //   title: 'Thanks. And what is your title?',
    //   type: 'input',
    // },
    'How many trucks do you have currently?': {
      title: 'How many trucks are currently in your fleet?',
      type: 'input',
      validate: 'number',
    },
    'What trailers do you mostly have currently?': {
      title: 'It would also help to know what trailers you mostly run.',
      type: 'radio',
      options: [
        { label: 'Dry van', value: 'Dry van' },
        { label: 'Refrigerated van', value: 'Refrigerated van' },
        { label: 'Flatbed/Lowbed', value: 'Flatbed/Lowbed' },
        { label: 'Tanker/Bulk', value: 'Tanker/Bulk' },
        { label: 'Specialized', value: 'Specialized' },
      ],
    },
    'What are your average loaded weights?': {
      title:
        'Thanks for that. Since weights impact fuel mileage, can you tell me about your average loaded weights?',
      type: 'radio',
      options: [
        { label: '10,000 - 50,000 lbs', value: '10,000 - 50,000 lbs' },
        { label: '50,000 - 80,000 lbs', value: '50,000 - 80,000 lbs' },
        { label: 'Over 80,000 lbs', value: 'Over 80,000 lbs' },
      ],
    },
    'What is your current fleet fuel economy?': {
      title:
        'Finally, what is your current fleet fuel economy? (Enter MPG value)',
      type: 'input',
      validate: 'number',
    },
  },
};

export const Q4_FUEL_DATA = {
  id: 9,
  question:
    'What are the keys to a successful driver fuel bonus program at my fleet?',
  inputs: {
    // 'What type of fleet do you have?': {
    //   title: 'Great question. First, tell me about your fleet.',
    //   type: 'radio',
    //   options: [
    //     { label: 'Truckload - national', value: 'Truckload - national' },
    //     { label: 'Truckload - regional', value: 'Truckload - regional' },
    //     { label: 'LTL - national', value: 'LTL - national' },
    //     { label: 'LTL - regional', value: 'LTL - regional' },
    //     { label: 'Private', value: 'Private' },
    //     { label: 'Specialty/Other', value: 'Specialty/Other' },
    //   ],
    // },
    // 'What is your title?': {
    //   title: 'Thanks. And what is your title?',
    //   type: 'input',
    // },
    'How many drivers do you have currently?': {
      title: 'How many drivers are currently in your fleet?',
      type: 'input',
      validate: 'number',
    },
    'What is your current fleet fuel economy?': {
      title: 'It would also help to know what trailers you mostly run.',
      type: 'checkbox',
      isAllowInput: false,
      options: [
        { label: 'Dry van', value: 'Dry van' },
        { label: 'Refrigerated van', value: 'Refrigerated van' },
        { label: 'Flatbed/Lowbed', value: 'Flatbed/Lowbed' },
        { label: 'Tanker/Bulk', value: 'Tanker/Bulk' },
        { label: 'Specialized', value: 'Specialized' },
      ],
    },
    'And are you using any type of driver fuel coaching program now?': {
      title:
        ' I see. And are you using any type of driver fuel coaching program now?',
      type: 'radio',
      options: [
        { label: 'Yes.', value: 'Yes' },
        { label: 'No.', value: 'No' },
      ],
    },
    'What is your current annual spend on fuel economy bonuses?': {
      title:
        'Great. Last question - What is your current annual spend on fuel economy bonuses?',
      type: 'input',
      validate: 'number',
    },
  },
};

export const Q5_FUEL_DATA = {
  id: 10,
  question: 'What are my requirements for fuel tax reporting?',
  inputs: {
    'Are your trucks generally operate regionally or across state lines?': {
      title:
        'Do your trucks generally operate regionally or across state lines?',
      type: 'radio',
      options: [
        {
          label: 'Regional routes within one state',
          value: 'Regional routes within one state',
        },
        { label: 'Across state lines', value: 'Across state lines' },
      ],
    },
    // 'What is your title?': {
    //   title: 'Thanks. And what is your title?',
    //   type: 'input',
    // },
    'How many trucks do you have currently?': {
      title: 'Got it. And how many trucks do you have currently? ',
      type: 'input',
      validate: 'number',
    },
    'Have you received any IFTA tax fines or penalties recently?': {
      title:
        'Great. Last question - have you received any IFTA tax fines or penalties recently?',
      type: 'radio',
      options: [
        { label: 'Yes, we have ', value: 'Yes' },
        { label: 'No fines so far', value: 'No' },
      ],
    },
  },
};

export const Q6_FUEL_DATA = {
  id: 11,
  question: 'How can I catch fuel theft in my fleet?',
  inputs: {
    // 'What type of fleet do you have?': {
    //   title: 'Great question. First, tell me about your fleet.',
    //   type: 'radio',
    //   options: [
    //     { label: 'Truckload - national', value: 'Truckload - national' },
    //     { label: 'Truckload - regional', value: 'Truckload - regional' },
    //     { label: 'LTL - national', value: 'LTL - national' },
    //     { label: 'LTL - regional', value: 'LTL - regional' },
    //     { label: 'Private', value: 'Private' },
    //     { label: 'Specialty/Other', value: 'Specialty/Other' },
    //   ],
    // },
    // 'What is your title?': {
    //   title: 'Got it. Next, what is your title?',
    //   type: 'input',
    // },
    'Are your drivers purchasing fuel with company-issued cards or their own credit?':
      {
        title:
          'Are your drivers purchasing fuel with company-issued cards or their own credit?',
        type: 'radio',
        options: [
          { label: 'Company fuel cards', value: 'Company fuel cards' },
          { label: 'Personal credit cards', value: 'Personal credit cards' },
        ],
      },
    'Are you using any system to monitor fuel transactions?': {
      title:
        'And what system, if any, are you using to monitor fuel transactions?',
      type: 'radio',
      options: [
        { label: 'Software program ', value: 'Software program ' },
        { label: 'Spreadsheets', value: 'Spreadsheets' },
        { label: 'None currently ', value: 'None currently ' },
      ],
    },
    'Approximately how large is your fleet?': {
      title: 'Got it. Approximately how large is your fleet?',
      type: 'radio',
      options: [
        { label: 'Under 10 trucks', value: 'Under 10 trucks' },
        { label: 'Between 10-50 trucks', value: 'Between 10-50 trucks' },
        { label: 'Over 50 trucks', value: 'Over 50 trucks' },
      ],
    },
    'Have you uncovered any suspicious charges or notable fuel card spending increases recently?':
      {
        title:
          'Have you uncovered any suspicious charges or notable fuel card spending increases recently? ',
        type: 'radio',
        options: [
          {
            label: 'Yes, we’re seeing issues',
            value: 'Yes, we’re seeing issues',
          },
          { label: 'No red flags so far', value: 'No red flags so far' },
          { label: 'Not monitoring closely', value: 'Not monitoring closely' },
        ],
      },
  },
};

export const Q7_FUEL_DATA = {
  id: 12,
  question:
    'Summarize all of the current data on driver-related impact on fuel economy.',
  inputs: {
    // 'What type of fleet do you have?': {
    //   title: 'Great question. First, tell me about your fleet.',
    //   type: 'radio',
    //   options: [
    //     { label: 'Truckload - national', value: 'Truckload - national' },
    //     { label: 'Truckload - regional', value: 'Truckload - regional' },
    //     { label: 'LTL - national', value: 'LTL - national' },
    //     { label: 'LTL - regional', value: 'LTL - regional' },
    //     { label: 'Private', value: 'Private' },
    //     { label: 'Specialty/Other', value: 'Specialty/Other' },
    //   ],
    // },
    // 'What is your title?': {
    //   title: 'Thanks. And what is your title?',
    //   type: 'input',
    // },
    'How many trucks do you have currently?': {
      title: 'How many trucks are currently in your fleet?',
      type: 'input',
      validate: 'number',
    },
    'What is your current fleet fuel economy?': {
      title:
        'Super. And what is your current fleet fuel economy? (Enter MPG value)',
      type: 'input',
      validate: 'number',
    },
    'Do you have active programs to coach drivers in fuel-efficient driving practices?':
      {
        title:
          'Finally, do you have active programs to coach drivers in fuel-efficient driving practices?',
        type: 'radio',
        options: [
          {
            label: 'Yes',
            value: 'Yes',
          },
          { label: 'No', value: 'No' },
        ],
      },
  },
};

export const Q8_FUEL_DATA = {
  id: 13,
  question: 'How does my speed governing policy compare to other fleets?',
  inputs: {
    // 'What type of fleet do you have?': {
    //   title: 'Before we get into that, what type of fleet do you have?',
    //   type: 'radio',
    //   options: [
    //     { label: 'Truckload - national', value: 'Truckload - national' },
    //     { label: 'Truckload - regional', value: 'Truckload - regional' },
    //     { label: 'LTL - national', value: 'LTL - national' },
    //     { label: 'LTL - regional', value: 'LTL - regional' },
    //     { label: 'Private', value: 'Private' },
    //     { label: 'Specialty/Other', value: 'Specialty/Other' },
    //   ],
    // },
    // 'What is your title?': {
    //   title: 'Got it. Next, what is your title?',
    //   type: 'input',
    // },
    'How many trucks do you have currently?': {
      title: 'How many trucks are currently in your fleet?',
      type: 'input',
      validate: 'number',
    },
    'What is your current fleet fuel economy?': {
      title:
        'Super. And what is your current fleet fuel economy? (Enter MPG value) ',
      type: 'input',
      validate: 'number',
    },
    'What is your current speed governing policy?': {
      title: 'Finally, what is your current speed governing policy?',
      type: 'radio',
      options: [
        {
          label: 'Under 60 mph',
          value: 'Under 60 mph',
        },
        { label: '60-65 mph', value: '60-65 mph' },
        { label: '65-70 mph', value: '65-70 mph' },
        { label: 'Over 70 mph', value: 'Over 70 mph' },
        { label: 'None', value: 'None' },
      ],
    },
  },
};

export const Q9_FUEL_DATA = {
  id: 14,
  question: 'How does my fuel cost per mile compare to the national average?',
  inputs: {
    // 'What type of fleet do you have?': {
    //   title:
    //     'Good question. First, can you tell me what type of fleet you have?',
    //   type: 'radio',
    //   options: [
    //     { label: 'Truckload - national', value: 'Truckload - national' },
    //     { label: 'Truckload - regional', value: 'Truckload - regional' },
    //     { label: 'LTL - national', value: 'LTL - national' },
    //     { label: 'LTL - regional', value: 'LTL - regional' },
    //     { label: 'Private', value: 'Private' },
    //     { label: 'Specialty/Other', value: 'Specialty/Other' },
    //   ],
    // },
    // 'What is your title?': {
    //   title: 'Got it. Next, what is your title?',
    //   type: 'input',
    // },
    'What is your average annual mileage per truck?': {
      title: 'What is your average annual mileage per truck?',
      type: 'input',
      validate: 'number',
    },
    'What is your fleet’s current fuel cost per mile?': {
      title:
        'OK, what is your fleet’s current fuel cost per mile? (enter dollar value)',
      type: 'input',
      validate: 'number',
    },
  },
};

export const Q10_FUEL_DATA = {
  id: 15,
  question:
    'How does the price I’m currently paying for diesel compare to the national average?',
  inputs: {
    // 'What type of fleet do you have?': {
    //   title:
    //     'Good question. Before we tell you, what type of fleet do you have?',
    //   type: 'radio',
    //   options: [
    //     { label: 'Truckload - national', value: 'Truckload - national' },
    //     { label: 'Truckload - regional', value: 'Truckload - regional' },
    //     { label: 'LTL - national', value: 'LTL - national' },
    //     { label: 'LTL - regional', value: 'LTL - regional' },
    //     { label: 'Private', value: 'Private' },
    //     { label: 'Specialty/Other', value: 'Specialty/Other' },
    //   ],
    // },
    // 'What is your title?': {
    //   title: 'Got it. Next, what is your title?',
    //   type: 'input',
    // },
    'How are you currently purchasing fuel?': {
      title: 'How are you currently purchasing fuel?',
      type: 'radio',
      options: [
        {
          label: 'All under contract tied to wholesale pricing fluctuations',
          value: 'All under contract tied to wholesale pricing fluctuations',
        },
        { label: 'All at retail', value: 'All at retail' },
        {
          label: 'Mix of contract and retail',
          value: 'Mix of contract and retail',
        },
      ],
    },
    'What are you currently paying on average for a gallon of diesel?': {
      title:
        'Perfect. Now, what are you currently paying on average for a gallon of diesel? (enter dollar amount)',
      type: 'input',
      validate: 'number',
    },
  },
};

export const Q11_FUEL_DATA = {
  id: 16,
  question: 'How does my deadhead mileage compare to fleets like mine?',
  inputs: {
    // 'What type of fleet do you have?': {
    //   title:
    //     'Great question. Before we answer, tell us about the type of fleet you have.',
    //   type: 'radio',
    //   options: [
    //     { label: 'Truckload - national', value: 'Truckload - national' },
    //     { label: 'Truckload - regional', value: 'Truckload - regional' },
    //     { label: 'LTL - national', value: 'LTL - national' },
    //     { label: 'LTL - regional', value: 'LTL - regional' },
    //     { label: 'Private', value: 'Private' },
    //     { label: 'Bulk/Tanker', value: 'Bulk/Tanker' },
    //     { label: 'Specialty/Other', value: 'Specialty/Other' },
    //   ],
    // },
    // 'What is your title?': {
    //   title: 'Got it. Next, what is your title?',
    //   type: 'input',
    // },
    'How large your fleet is?': {
      title: 'How large is your fleet?',
      type: 'radio',
      options: [
        {
          label: 'Less than 26 trucks',
          value: 'Less than 26 trucks',
        },
        { label: '26 to 100 trucks', value: '26 to 100 trucks' },
        {
          label: '101-250 trucks',
          value: '101-250 trucks',
        },
        {
          label: '251 to 1,000 trucks',
          value: '251 to 1,000 trucks',
        },
        {
          label: 'Over 1,000 trucks',
          value: 'Over 1,000 trucks',
        },
      ],
    },
    'What was your deadhead mileage as a percentage of total truck mileage in the most recent month?':
      {
        title:
          'What was your deadhead mileage as a percentage of total truck mileage in the most recent month? (enter percentage)',
        type: 'input',
        validate: 'number',
      },
  },
};

export const FUEL_QUESTIONS = [
  Q1_FUEL_DATA,
  Q2_FUEL_DATA,
  Q4_FUEL_DATA,
  Q5_FUEL_DATA,
  Q6_FUEL_DATA,
  Q7_FUEL_DATA,
  Q8_FUEL_DATA,
  Q9_FUEL_DATA,
  Q10_FUEL_DATA,
  Q11_FUEL_DATA,
];
