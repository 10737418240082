import { Dispatch, SetStateAction, useState } from 'react';
import { Typography } from 'antd';

import { LineChart } from '../LineChart';
import { MoreInfoModal } from '../moreInfoModal/MoreInfoModal';
import { IChartData, IWeight } from '../../../../types/analitics';
import { MainButton } from '../../../../components';
import S from './style.module.css';

interface ChartContainerProps {
  data: IChartData;
  weights: IWeight[];
  defaultWeights: IWeight[];
  setWeights: (weights: IWeight[]) => Promise<void>;
}

export const ChartContainer = ({
  data,
  weights,
  defaultWeights,
  setWeights,
}: ChartContainerProps) => {
  const [modalOpened, setModalOpened] = useState<boolean>(false);

  const { topic } = data;

  return (
    <div className={S.chartContainer}>
      <div className={S.titleContainer}>
        <Typography.Title className={S.title} level={2}>
          {topic}
        </Typography.Title>
        <MainButton
          onClick={() => setModalOpened(true)}
          className={S.moreInfo}
          type="text"
        >
          More info
        </MainButton>
      </div>
      <div style={{ width: '100%', overflow: 'auto' }}>
        <LineChart data={data} />
      </div>
      <MoreInfoModal
        modalOpened={modalOpened}
        setModalOpened={setModalOpened}
        topic={topic}
        weights={weights}
        defaultWeights={defaultWeights}
        setWeights={setWeights}
      />
    </div>
  );
};

export default ChartContainer;
