import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';

import { useAppSelector, useAppDispatch } from '../../../hooks/hooks';
import * as userSelectors from '../../../stores/user/selectors';
import { actions as alertActions } from '../../../stores/alert/slice';
import { ChatService } from '../../../services/chat/api';
import { TRUCKANSWERS_SENDER, YOU_SENDER } from '../../../shared/const/common';
import { ALL_QUESTIONS } from '../../../shared/const/questions/index';

const useChat = ({ form }) => {
  const dispatch = useAppDispatch();
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();
  const params = useParams();
  const { sessionUuid, companyName, fleetType } =
    useAppSelector(userSelectors.getUserData) || {};
  const { getFieldValue, getFieldsValue } = form;

  const { id, inputs, question } = ALL_QUESTIONS.find(
    ({ id }) => id === +params.id,
  );

  const [messages, setMessages] = useState([
    { title: question, sender: YOU_SENDER },
  ]);
  const [activeQuestion, setActiveQuestion] = useState(
    inputs[Object.keys(inputs)[0]],
  );
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const questionsArr = Object.keys(inputs);
  const questionKey = questionsArr[currentQuestionIndex];

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const question = Object.values(inputs)[currentQuestionIndex];
      if (question) {
        setMessages([
          ...messages,
          {
            ...question,
            sender: TRUCKANSWERS_SENDER,
          },
        ]);
      }

      setActiveQuestion(question);
    }, 500);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionKey]);

  const onSubmitQuestion = () => {
    if (activeQuestion.type === 'checkbox') {
      const otherInputValue = getFieldValue(`${questionKey}_other`);
      const existingValues = getFieldValue(questionKey) || [];

      form.setFieldsValue({
        [questionKey]: [
          ...existingValues,
          ...(otherInputValue ? [otherInputValue] : []),
        ].join(', '),
      });
      form.setFieldsValue({ [`${questionKey}_other`]: undefined });
    }
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        title:
          activeQuestion.type === 'multiple-inputs'
            ? activeQuestion.inputs.map((item) => (
                <span style={{ display: 'block' }} key={item.name}>
                  <span>{item.label}:</span> {getFieldValue(item.name)}
                </span>
              ))
            : getFieldValue(questionKey),
        sender: YOU_SENDER,
        question: questionKey,
      },
    ]);
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    setActiveQuestion(null);
  };

  const onSubmitChat = async () => {
    const values = getFieldsValue(true);
    const userInfo = {
      'What type of fleet do you have?': fleetType,
      'What is your title?': companyName,
    };
    const inputs = { ...values, ...userInfo };

    try {
      setIsLoading(true);

      const { data } = await ChatService.getAnswer({
        id,
        session_uuid: sessionUuid,
        question,
        inputs: inputs,
      });

      navigate(`success`, {
        state: { results: data.results },
      });
    } catch (error) {
      dispatch(
        alertActions.addAlert({
          message: 'Error fetching answer',
          type: 'error',
          status: error instanceof AxiosError ? error?.response?.status : '',
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return {
    messagesEndRef,
    onSubmitQuestion,
    messages,
    isLoading,
    activeQuestion,
    questionKey,
    onSubmitChat,
  };
};

export default useChat;
