import { type AxiosResponse } from 'axios';
import { IChartData, IWeight } from '../../types/analitics';
import { ENDPOINTS } from '../../shared/const/endpoints';
import makeRequest from '../../shared/utils/makeRequest';

export class AnalyticsService {
  static async getDatasets(): Promise<AxiosResponse<IChartData[]>> {
    return makeRequest({
      method: 'get',
      url: ENDPOINTS.GET_DATASETS,
    });
  }

  static async getWeights(): Promise<AxiosResponse<IWeight[]>> {
    return makeRequest({
      method: 'get',
      url: ENDPOINTS.GET_WEIGHTS,
    });
  }

  static async getDefaultWeights(): Promise<AxiosResponse<IWeight[]>> {
    return makeRequest({
      method: 'get',
      url: ENDPOINTS.GET_DEFAULT_WEIGHTS,
    });
  }

  static async setWeights(data: IWeight[]): Promise<AxiosResponse<IWeight[]>> {
    return makeRequest({
      method: 'patch',
      url: ENDPOINTS.GET_WEIGHTS,
      data,
    });
  }
}
