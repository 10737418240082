import { Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';

import { MainButton } from '../../../../components';
import S from './style.module.css';

const TOPICS_LINKS = [
  { key: 'Labor', link: 'labor' },
  { key: 'Fuel', link: 'fuel' },
  { key: 'Safety', link: 'safety' },
  { key: 'Financial', link: 'financial' },
  { key: 'Maintenance', link: 'maintenance' },
];

export const Footer = () => {
  const { topic } = useParams();

  const filteredLinks = TOPICS_LINKS.filter(
    (item) => item.key.toLowerCase() !== topic,
  );

  return (
    <div className={S.footer_container}>
      Jump to{' '}
      {filteredLinks.map((link, index) => (
        <Fragment key={index}>
          <Link to={`../../../${link.link}`} key={link.key} relative="path">
            <MainButton htmlType="button" type="text" className={S.linkBtn}>
              {link.key}
            </MainButton>
          </Link>
          {index < filteredLinks.length - 1 ? ' or ' : ''}
        </Fragment>
      ))}
    </div>
  );
};

export default Footer;
