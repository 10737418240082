import { useEffect, useState } from 'react';
import { Typography } from 'antd';
import { AxiosError } from 'axios';

import { IChartData, IWeight } from './../../types/analitics';
import { AnalyticsService } from '../../services/analytics/api';
import { Loading } from '../../components/Loading/Loading';
import { ChartContainer } from './components/chatContainer/ChartContainer';
import { useAppDispatch } from '../../hooks/hooks';
import { actions as alertActions } from '../../stores/alert/slice';
import S from './analytics.module.css';

const DataSources = [
  {
    label: 'My Index',
    borderColor: 'rgba(211, 30, 37, 1)',
    backgroundColor: 'rgba(211, 30, 37, 1)',
  },
  {
    label: 'National Index',
    borderColor: 'rgba(20, 20, 23, 1)',
    backgroundColor: 'rgba(20, 20, 23, 1)',
  },
];

export const Analytics = () => {
  const dispatch = useAppDispatch();
  const [fetchData, setFetchData] = useState<IChartData[]>([]);
  const [weights, setWeights] = useState<IWeight[]>([]);
  const [defaultWeights, setDefaultWeights] = useState<IWeight[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const { data: weights } = await AnalyticsService.getWeights();
        const { data: defaultWeights } =
          await AnalyticsService.getDefaultWeights();
        const { data } = await AnalyticsService.getDatasets();

        if (!(data && weights && defaultWeights)) return;

        setFetchData(data);
        setWeights(weights);
        setDefaultWeights(defaultWeights);
      } catch (error) {
        dispatch(
          alertActions.addAlert({
            message: 'Data fetching has been failed',
            type: 'error',
            status: error instanceof AxiosError ? error.response?.status : '',
          }),
        );
      } finally {
        setLoading(false);
      }
    };

    getData();
    // eslint-disable-next-line
  }, []);

  const setNewWeights = async (newWeights: IWeight[]) => {
    const { data: weights } = await AnalyticsService.setWeights(newWeights);
    const { data } = await AnalyticsService.getDatasets();

    if (!(data && weights)) return;

    setWeights(weights);
    setFetchData(data);
  };

  const chartsData = fetchData.map(({ topic, datasets }) => {
    const chartDatasets = datasets.map(
      ({ data }: { data: any }, index: number) => ({
        ...DataSources[index],
        data,
      }),
    );

    return { topic, datasets: chartDatasets };
  });

  if (loading) {
    return <Loading size="large" />;
  }

  return (
    <div className={S.container}>
      <Typography.Title className={S.title} level={1}>
        Trucking Cost Indexes
      </Typography.Title>
      {chartsData?.map((data, index) => (
        <ChartContainer
          data={data}
          weights={weights}
          defaultWeights={defaultWeights}
          setWeights={setNewWeights}
          key={index + data.topic}
        />
      ))}
    </div>
  );
};

export default Analytics;
