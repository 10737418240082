export const Q1_FINANCIAL_DATA = {
  id: 24,
  question:
    'How does my operating cost per mile compare to the current national average?',
  inputs: {
    // 'Fleet type': {
    //   title:
    //     'Great question. To give you a good answer, tell me what type of fleet you have.',
    //   type: 'radio',
    //   options: [
    //     { label: 'Truckload - national', value: 'Truckload - national' },
    //     { label: 'Truckload - regional', value: 'Truckload - regional' },
    //     { label: 'LTL - national', value: 'LTL - national' },
    //     { label: 'LTL - regional', value: 'LTL - regional' },
    //     { label: 'Private', value: 'Private' },
    //     { label: 'Specialty/Other', value: 'Specialty/Other' },
    //   ],
    // },
    // 'What is your title?': {
    //   title: 'Thanks. And what is your title',
    //   type: 'input',
    // },
    'What is your fleet’s current operating cost per mile?': {
      title:
        'What is your fleet’s current total operating cost per mile, including driver compensation? (enter numeric value)',
      type: 'input',
      validate: 'number',
    },
  },
};

export const Q2_FINANCIAL_DATA = {
  id: 25,
  question: 'How does my operating margin compare to the industry average?',
  inputs: {
    // 'What type of fleet do you have?': {
    //   title:
    //     'Great question. Before we answer, tell us about the type of fleet you have',
    //   type: 'radio',
    //   options: [
    //     { label: 'Truckload - national', value: 'Truckload - national' },
    //     { label: 'Truckload - regional', value: 'Truckload - regional' },
    //     { label: 'LTL - national', value: 'LTL - national' },
    //     { label: 'LTL - regional', value: 'LTL - regional' },
    //     { label: 'Private', value: 'Private' },
    //     { label: 'Specialty/Other', value: 'Specialty/Other' },
    //   ],
    // },
    // 'What is your title?': {
    //   title: 'Got it. Next, what is your title?',
    //   type: 'input',
    // },
    'Average annual mileage per truck': {
      title: 'How large is your fleet?',
      type: 'radio',
      options: [
        { label: 'Less than 26 trucks', value: 'Less than 26 trucks' },
        { label: '26 to 100 trucks', value: '26 to 100 trucks' },
        { label: '101-250 trucks', value: '101-250 trucks' },
        { label: '251 to 1,000 trucks', value: '251 to 1,000 trucks' },
        { label: 'Over 1,000 trucks', value: 'Over 1,000 trucks' },
      ],
    },
    'What your fleet’s operating margin was in the most recent financial reporting period?':
      {
        title:
          'Thanks a lot. Now tell us what your fleet’s operating margin was in the most recent financial reporting period? (percentage value)',
        type: 'input',
        validate: 'number_with_negative',
      },
  },
};

export const Q3_FINANCIAL_DATA = {
  id: 26,
  question: 'How does my debt-to-equity ratio compare to the industry average?',
  inputs: {
    // 'What type of fleet do you have?': {
    //   title:
    //     'Great question. Before we answer, tell us about the type of fleet you have',
    //   type: 'radio',
    //   options: [
    //     { label: 'Truckload - national', value: 'Truckload - national' },
    //     { label: 'Truckload - regional', value: 'Truckload - regional' },
    //     { label: 'LTL - national', value: 'LTL - national' },
    //     { label: 'LTL - regional', value: 'LTL - regional' },
    //     { label: 'Private', value: 'Private' },
    //     { label: 'Specialty/Other', value: 'Specialty/Other' },
    //   ],
    // },
    'What your fleet’s debt-to-equity ratio was in the most recent financial reporting period?':
      {
        title:
          'What was your fleet’s debt-to-equity ratio in the most recent financial reporting period? (numeric value)',
        type: 'input',
        validate: 'number',
      },
  },
};

export const FINANCIAL_QUESTIONS = [
  Q1_FINANCIAL_DATA,
  Q2_FINANCIAL_DATA,
  Q3_FINANCIAL_DATA,
];
