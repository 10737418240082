export const Paths = {
  AUTH: 'auth',
  LOG_IN: 'log-in',
  SIGN_UP: 'sign-up',
  FORGOT_PASSWORD: 'forgot-password',
  CHANGE_PASSWORD_SUCCESS: 'change-password-success',
  REGISTRATION_SUCCESS: 'registration-success',
  ACTIVATE_EMAIL: 'activate/:uid/:token',
  CHECK_YOUR_EMAIL: 'check-your-email',
  RESET_PASSWORD_CONFIRM: 'password/reset/confirm/:uid/:token',
  APP: {
    INDEX: '/app',
    INDEXES: 'indexes',
    ASSISTANT: {
      INDEX: 'assistant',
      TOPIC: ':topic',
      CHAT: ':topic/:id',
      SUCCESSVIEW: ':topic/:id/success',
      LABOR: 'labor',
      FUEL: 'fuel',
      SAFETY: 'safety',
      MAINTENANCE: 'maintenance',
      FINANCIAL: 'financial',
    },
    PROFILE: {
      INDEX: 'profile',
      EDIT_PROFILE: 'edit-profile',
      PASSWORD: 'password',
    },
    DRIVER_COMPENSATION: 'driver-compensation',
  },
};
