import { IndexType } from '../../../../types/analitics';
import { Table, TableProps } from 'antd';

interface MoreInfoDesktopTableProps {
  data: IndexType[];
  getInputNumber: (value: any, title: string) => JSX.Element;
}

export const MoreInfoDesktopTable = ({
  data,
  getInputNumber,
}: MoreInfoDesktopTableProps) => {
  const columns: TableProps<IndexType>['columns'] = [
    {
      title: 'Components',
      dataIndex: 'title',
      key: 'Components',
    },
    {
      title: 'Weighting',
      dataIndex: 'weight',
      key: 'Components',
      render: (value, { title }) => {
        return getInputNumber(value, title);
      },
    },
    {
      title: 'Component Calculation',
      dataIndex: 'calculation',
      key: 'Components',
      render: (value, { weight }) => `${value} x ${weight / 100}`,
    },
  ];

  return (
    <Table bordered columns={columns} dataSource={data} pagination={false} />
  );
};
