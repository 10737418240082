import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import { IChartData } from './../../../types/analitics';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  responsive: true,
  aspectRatio: 4 / 1,
  plugins: {
    legend: {
      position: 'top' as const,
      align: 'end' as const,
      labels: {
        boxWidth: 21,
        boxHeight: 21,
        useBorderRadius: true,
        borderRadius: 4,
        padding: 24,
      },
    },
    tooltip: {
      boxPadding: 4,
    },
  },
};

export const LineChart = ({ data }: { data: IChartData }) => {
  const { datasets } = data;

  const dates: string[] = datasets.reduce((result: any, dataset) => {
    const points = dataset.data;

    points.forEach(({ x, y }) => (result[x] = y));

    return result;
  }, {});

  const labels = Object.keys(dates).sort((a, b) =>
    a === b ? 0 : a > b ? 1 : -1,
  );

  data.labels = labels;

  return (
    <div style={{ minWidth: 1024 }}>
      <Line options={options} data={data} />
    </div>
  );
};

export default LineChart;
